import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  hasResume: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      //console.log('hellloooooooooooooooooooo', action.payload);
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.talentCard = action.payload.talentCard;
      state.hasResume = action.payload.hasResume;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.hasResume = action.payload.hasResume;
      // state.talent = action.payload.talent
    },

    // REGISTER
    registerSuccess(state, action) {
      // state.isAuthenticated = true;
      state.user = action.payload;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    const response = await axios.post('/api/auth/login', {
      username: email,
      password
    });
    const { talent, hasResume, user, token } = response.data;
    setSession(token);
    dispatch(slice.actions.loginSuccess({ talent, hasResume, user  }));
    // await dispatch(getInitialize()); 
  };
}

// ----------------------------------------------------------------------

export function register({
  email,
  password,
  firstName,
  lastName,
  contact,
  job_role
}) {
  return async (dispatch) => {
    const response = await axios.post('/api/auth/register', {
      username: email,
      password,
      firstName,
      lastName,
      contactNumber: contact,
      currentDesignation: job_role
    });
    const { accessToken, user } = response.data;

    // window.localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('authenticatedUser', JSON.stringify({ email: email, firstName: firstName }));
    dispatch(slice.actions.registerSuccess(response.data));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
    window.localStorage.removeItem('accessToken');
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    //console.log("GET INITIALIZE");
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      //console.log('decode', accessToken, jwtDecode(accessToken));
      let talentId = jwtDecode(accessToken)._id;
      if (accessToken) {
        setSession(accessToken);

        const response = await axios.get(`api/talent/${talentId}`);
        //console.log('response is ', response);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user,
            talentCard: response.data.talent,
            hasResume: response.data.hasResume
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
